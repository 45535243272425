import React, {FC} from 'react';
import {Link} from "react-router-dom";

type Props = {
    href?: any
    className?: any
    text?: any
    withLogo?: boolean
    onClick?: () => void
    isLoading?: boolean
    btnLogo?: any
    target ?: boolean
}

const MainButton: FC<Props> = ({href, className, text, withLogo, onClick, isLoading, btnLogo, target}) => {
    return (
        <>
            {onClick ?
                <button className={`${className}`} onClick={onClick}>
                    {!isLoading && (
                        <span className="indicator-label"> {text}
                            {btnLogo ?
                                <img className={'ms-lg-2 ms-1 logo-icon'} src={btnLogo} alt={'Logo'}/>
                                : withLogo ?
                                    <img className={'ms-lg-2 ms-1 logo-icon'} src={'/assets/images/logos/mini-logo.png'}
                                         alt={'Logo'}/> : ''
                            }
                        </span>
                    )}
                    {isLoading && (
                        <span className="indicator-progress" style={{display: "block"}}>
                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>)}
                </button>
                :
                <Link target={target ? '_blank' : ''} to={href} className={`${className}`}> {text}
                    {btnLogo ?
                        <img className={'ms-lg-2 ms-1 logo-icon'} src={btnLogo} alt={'Logo'}/>
                        : withLogo ?
                            <img className={'ms-lg-2 ms-1 logo-icon'} src={'/assets/images/logos/mini-logo.png'}
                                 alt={'Logo'}/> : ''
                    }</Link>
            }
        </>
    );
};

export default MainButton;