import React, { FC, lazy, Suspense, useEffect, useState } from "react";
import { SocialMeta } from "../../components/SocialMeta";
import { Tab, Tabs } from "react-bootstrap";
import { useCart } from "../../context/CartContext";
import { useNavigate, useParams } from "react-router-dom";
import { useLanguage } from "../../context/LanguageContext";
import { t } from "i18next";
import {showProduct} from "../../core/product/requests";
import MainButton from "../../components/buttons/MainButton";


const Hero = lazy(() => import("../../components/Hero"));
const ProductImages = lazy(() => import("./components/ProductImages"));
const MainText = lazy(() => import("../../components/text/MainText"));
const AsideImage = lazy(() => import("../../layout/AsideImage"));
const NumberFormat = lazy(() => import("../../components/text/NumberFormat"));

const SecondaryProductShow: FC = () => {
    const { currentLanguageCode } = useLanguage();
    const [product, setProduct] = useState<any>();
    const [localQuantity, setLocalQuantity] = useState<number>(1); // Local state for product quantity
    const params = useParams();
    const { addToCart} = useCart();

    const [breadcrumb, setBreadcrumb] = useState([
        { label: t('home.home'), href: '/' },
        { label: 'Shop', href: '/shop' },
    ]);


    const handleAddToCart = () => {
        if (product) {
            addToCart(product, localQuantity); // Pass the localQuantity
        }
    };


    const handleIncreaseQuantity = () => {
        setLocalQuantity(prevQuantity => prevQuantity + 1);
    };

    const handleDecreaseQuantity = () => {
        setLocalQuantity(prevQuantity => Math.max(prevQuantity - 1, 1)); // Ensure quantity does not go below 1
    };

    useEffect(() => {
        showProduct(0 , params.product, currentLanguageCode).then((response) => {
            setProduct(response);
            setLocalQuantity(1); // Reset local quantity when product is fetched
            setBreadcrumb((prevBreadcrumb) => [
                ...prevBreadcrumb.slice(0, 2),
                { label: response.name, href: '' },
            ]);
        });

    }, [params.project, params.product, currentLanguageCode]);

    return (
        <>
            <SocialMeta
                title={product && product.meta_title ? product.meta_title : product && product.name ? product.name + ' - Garden of Life' : 'Garden of Life'}
                keyword={product && product.name ? product.name : 'Garden of Life'}
                description={product && product.meta_description ? product.meta_description : product && product.name ? product.name + ' - Garden of Life' : 'Garden of Life'}
                name={'Garden Of Life'}
                link={window.location.href}
            />
            <section>
                {product &&
                    <div className={'container mb-5'}>
                        <Suspense fallback={<></>}>
                            <Hero title={''} text={''} breadcrumb={breadcrumb} description={''} />
                        </Suspense>
                        <div className={'mt-5 pt-5'}>
                            <div className={'row bg-light-2 bdr-21 p-4 '}>
                                <div className={'col-lg-5 mb-5'}>
                                    <div className={'images-slider bg-white bdr-21'}>
                                        <Suspense fallback={<></>}>
                                            {product.images && product.images.length > 0 &&
                                                <ProductImages images={product.images} />
                                            }
                                        </Suspense>
                                    </div>
                                </div>
                                <div className="col-lg-7 mb-5 p-lg-4 ">
                                    <div>
                                        <Suspense fallback={<></>}>
                                            <h1 className="title-h2 primary-text">{product.name}</h1>
                                            <MainText className={'main-p text-color text-start pe-lg-5 me-lg-5'}
                                                      text={product.description} />
                                        </Suspense>
                                        <div className={'main-p text-color text-start circle-checklist mt-3 mb-2'}
                                             dangerouslySetInnerHTML={{ __html: product.benefits }} />

                                        <div className={'primary-title text-start mt-lg-4 mt-3 mb-4'}>
                                            USD <NumberFormat value={product.price} />
                                        </div>

                                        <hr className={'separator-2'} />

                                        <div className="row mt-1 mb-4 align-items-center">

                                            <div className={'col-lg-4'}>
                                                <div className={'fs-18 f-title-regular mb-2'}>
                                                    {t('purchase.quantity')}
                                                </div>
                                                <div className="quantity-counter">
                                                    <button type="button" onClick={handleDecreaseQuantity}>-</button>
                                                    <span>{localQuantity}</span>
                                                    <button type="button" onClick={handleIncreaseQuantity}>+</button>
                                                </div>
                                            </div>
                                            <div className="col-lg-4">
                                                <MainButton href={``} className={`btn btn-primary-dark w-100`}
                                                            text={t('buttons.addToCart')} withLogo={true}
                                                            onClick={handleAddToCart}

                                                />
                                            </div>


                                        </div>

                                        <hr className={'separator-2'}/>


                                    </div>
                                </div>

                                {product.product_sections && product.product_sections.length > 0 &&
                                    <div className="col-lg-12">
                                        <Tabs
                                            defaultActiveKey={product.product_sections[0].section.slug}
                                            id="uncontrolled-tab-example"
                                        >
                                            {product.product_sections.map((productSection: any) => (
                                                <Tab key={productSection.id} eventKey={productSection.section.slug}
                                                     title={productSection.section.name}>
                                                    <div className={'text-transform-none'}
                                                         dangerouslySetInnerHTML={{ __html: productSection.description }} />
                                                </Tab>
                                            ))}
                                        </Tabs>
                                    </div>
                                }
                                <Suspense fallback={<></>}>
                                    <AsideImage className={'placeholder-3'} />
                                </Suspense>
                            </div>
                        </div>
                    </div>
                }
            </section>


        </>
    );
};

export default SecondaryProductShow;
